import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/banner.png" className="App-logo" alt="logo" />
        <div class="App-link-container">
          <p>New site is under construction!</p>
          <div class="App-link-row">
            <span class="App-link-label">Staking:</span>
            <a
              className="App-link"
              href="https://stake.gorillae.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              stake.gorillae.io
            </a>
            <span class="App-link-label">Old website:</span>
            <a
              className="App-link"
              href="https://gorillaenft.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              gorillaenft.io
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
